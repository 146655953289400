import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";

import { GetData as t } from "../../lingual";
import { Routes } from "../../routes";
import { Card } from "@themesberg/react-bootstrap";
import "./ContactUs.less";
import { notification, Form, Input, Button, Switch, Spin, Upload } from "antd";
import LocalStorageService from "../../services/storage/LocalStorageService";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import axios from "../../axios";

import { logout } from "../../utils";

class WorkAddEdit extends Component {
  constructor(props) {
    super(props);

    const localStorageService = LocalStorageService.getService();

    const token = localStorageService.getAccessToken();
    const props1 = {
      name: "image",
      action: BASE_URL + "/uploadImage",
      headers: {
        authorization: "authorization-text",
        "x-access-token": token,
      },
    };

    this.state = {
      title: t("contact-us"),
      description: t("add_new_work"),
      columns: [],
      values: { status: true },
      gotData: false,
      active: true,
      submitButtonName: t("submit"),
      uploadprops: props1,
      uploadvaluepic_square: "",
      uploadvaluepic_wide: "",
      htmleditervaluehtml: "",
    };

    this.onValuesChange = this.onValuesChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
    this.switchOnChange = this.switchOnChange.bind(this);

    this.uploadOnChangepic_square = this.uploadOnChangepic_square.bind(this);
    this.uploadOnChangepic_wide = this.uploadOnChangepic_wide.bind(this);
    this.htmlediterOnChangehtml = this.htmlediterOnChangehtml.bind(this);
  }

  uploadOnChangepic_square = (value) => {
    console.log(this.state.uploadvaluepic_square, "pic_square");
    this.setState({ uploadvaluepic_square: "" });
  };
  uploadOnChangepic_wide = (value) => {
    console.log(this.state.uploadvaluepic_wide, "pic_wide");
    this.setState({ uploadvaluepic_wide: "" });
  };
  htmlediterOnChangehtml = (value) => {
    this.setState({ htmleditervaluehtml: value });
  };
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  formRef = React.createRef();
  onValuesChange = (d) => {};

  onFinish = (values) => {

    console.log(values, "values >>>>>>>>>>>>>>>>>>>>>>>>>>")

    values.status = this.state.active ? 1 : 0;

    console.log(this.state.uploadvaluepic_square);
    if (this.state.uploadvaluepic_square != "") {
      values.pic_square = this.state.uploadvaluepic_square;
    } else {
      values.pic_square = values.poster_image_url?.file.response.filename;
    }
    console.log(this.state.uploadvaluepic_wide);
    if (this.state.uploadvaluepic_wide != "") {
      values.poster_image_url = this.state.uploadvaluepic_wide;
    } else {
      values.poster_image_url = values.poster_image_url?.file.response.filename;
    }
    values.html = this.state.htmleditervaluehtml;

    let path = "contact-us";
    if (this.props.match.params.id === "new") {
      axios
        .post(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("work"),
                res.data.error.message
              );
            } else {
              this.cancelFun();
              this.openNotificationWithIcon(
                "success",
                t("work"),
                t("data_added_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      path += "/" + this.props.match.params.id;
      axios
        .put(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                "recent-Work",
                res.data.error.message
              );
            } else {
              this.cancelFun();
              this.openNotificationWithIcon(
                "success",
                "recent-Work",
                t("data_updated_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    }
  };
  onFinishFailed = (values) => {};

  switchOnChange = (checked) => {
    this.setState({ active: checked });
  };
  cancelFun = () => {
    // {pathname:this.props.history.goBack,state:this.props.location.state}
    this.props.history.push({
      pathname: Routes.ContactUs.pathview,
      state: this.props.location.state,
    });
  };

  componentDidMount(prevProps) {
    if (this.props.match.params.id !== "new") {
      this.setState({
        description: t("edit_work"),
        submitButtonName: t("update"),
      });

      let path = "contact-us/" + this.props.match.params.id;
      axios
        .get(path)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("role_permission"),
                res.data.error.message
              );
            } else {
              
              const updatedData = res.data.data

              const arrOfUserSelectedItems = updatedData.user_selectedItems.split(",")

              
              updatedData.full_name = updatedData.first_name + " " + updatedData.last_name
             
              updatedData.selected_item_one = arrOfUserSelectedItems[0]
              updatedData.selected_item_two = arrOfUserSelectedItems[1]
              updatedData.selected_item_three = arrOfUserSelectedItems[2]
              updatedData.selected_item_four = arrOfUserSelectedItems[3]


              console.log(updatedData, "data >>>>>>>>>>>>>>>>>");


              
              
              this.setState({
                values: updatedData,

                gotData: true,
                active: res.data.data.status,
              });
              this.setState({
                uploadvaluepic_square: res.data.data.pic_square,
              });
              this.setState({ uploadvaluepic_wide: res.data.data.pic_wide });
              this.setState({ htmleditervaluehtml: res.data.data.html });
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      this.setState({ gotData: true });
    }
  }

  render() {
    return (
      <>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{this.state.title}</h4>
            <p className="mb-0">{this.state.description}</p>
          </div>
        </div>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            {this.state.gotData ? (
              <Form
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={this.state.values}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                size="default"
                ref={this.formRef}
              >
                <Form.Item label={t("id")} key="100" name="contact_us_id">
                  <Input placeholder={t("auto_incerment")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("Name")}
                  key="0"
                  name="full_name"
                  rules={[
                    { required: 1, message: t("Name") },
                  ]}
                >
                  <Input placeholder={t("enter_a_project_name")} disabled="true" />
                </Form.Item>
                {/* <Form.Item
                  label={t("last_name")}
                  key="1"
                  name="last_name"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("video_link")} disabled="true" />
                </Form.Item> */}
                <Form.Item
                  label={t("user_phone")}
                  key="1"
                  name="user_phone"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("user_phone")} disabled="true" />
                </Form.Item>

                <Form.Item
                  label={t("user_email")}
                  key="1"
                  name="user_email"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("user_email")} disabled="true" />
                </Form.Item>

                <Form.Item
                  label={t("user_role")}
                  key="1"
                  name="user_role"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("user_role")} disabled="true" />
                </Form.Item>
               
                <Form.Item
                  label={t("joining_date")}
                  key="1"
                  name="joining_date"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("user_role")} disabled="true" />
                </Form.Item>

                <Form.Item
                  label={t("company")}
                  key="1"
                  name="company"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("company")} disabled="true" />
                </Form.Item>

                <Form.Item
                  label={t("description")}
                  key="1"
                  name="description"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("description")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("deadline")}
                  key="1"
                  name="deadline"
                  rules={[
                    { required: 1, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("deadline")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("selected_item_one")}
                  key="1"
                  name="selected_item_one"
                  rules={[
                    { required: 1, message: t("selected_item_one") },
                  ]}
                >
                  <Input placeholder={t("selected_item_one")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("selected_item_two")}
                  key="1"
                  name="selected_item_two"
                  rules={[
                    { required: 1, message: t("selected_item_two") },
                  ]}
                >
                  <Input placeholder={t("selected_item_two")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("selected_item_three")}
                  key="1"
                  name="selected_item_three"
                  rules={[
                    { required: 1, message: t("selected_item_three") },
                  ]}
                >
                  <Input placeholder={t("selected_item_three")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("selected_item_four")}
                  key="1"
                  name="selected_item_four"
                  rules={[
                    { required: 1, message: t("selected_item_four") },
                  ]}
                >
                  <Input placeholder={t("selected_item_four")} disabled="true" />
                </Form.Item>
               
               
               
               
             
              
                {/* <Form.Item
                  label="user_phone"
                  key="11"
                  name="user_phone"
                  rules={[
                    { required: 1, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_wide}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item> */}
              
               
            
                
               
                {/* <Form.Item label={t("active")} key="101">
                  <Switch
                    name="status"
                    key="101"
                    onChange={this.switchOnChange}
                    defaultChecked={this.state.values.status}
                  />
                </Form.Item> */}
                <Form.Item justify="center">
                  <Button className="paddme" onClick={this.cancelFun}>
                    {t("cancel")}
                  </Button>

                  {/* <Button type="primary" htmlType="submit">
                    {this.state.submitButtonName}
                  </Button> */}
                </Form.Item>
              </Form>
            ) : (
              <div className="loadingpsin">
                <Spin />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default WorkAddEdit;
 