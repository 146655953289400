import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Breadcrumb } from '@themesberg/react-bootstrap';

// import { Card } from '@themesberg/react-bootstrap';

// import { Table, Tag, Space, Popconfirm, notification, Spin } from 'antd';

// import axios from "../../axios";

// import storage from "../../services/storage/LocalStorageService"

// import { logout } from '../../utils';

import { Nav, Accordion } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

class NavItem extends Component {
  constructor(props) {
    super(props);

    const { link, external, badgeText } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    const linkProps = external ? { href: link } : { as: Link, to: link };
    this.state = {
      classNames: classNames,
      linkProps: linkProps,
      badgeBg: "secondary",
      badgeColor: "primary",
      link: link,
    };
  }

  render() {
    let pathname = window.location.pathname;

    const { eventKey, title, icon, children = null } = this.props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    if (title === "-") {
      return <Nav className="flex-column">{children}</Nav>;
    }
    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}

export default NavItem;
