import React, { Component } from "react";
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faHome } from "@fortawesome/free-solid-svg-icons";
    import SimpleBar from "simplebar-react";
    import { useLocation } from "react-router-dom";
    import { CSSTransition } from "react-transition-group";
    import { GetData as t } from "../lingual";
    import {
      faBook,
      faBoxOpen,
      faChartPie,
      faCog,
      faFileAlt,
      faHandHoldingUsd,
      faSignOutAlt,
      faTable,
      faTimes,
    } from "@fortawesome/free-solid-svg-icons";
    
    import {
      Nav,
      Badge,
      Image,
      Button,
      Dropdown,
      Accordion,
      Navbar,
    } from "@themesberg/react-bootstrap";
    import { Link } from "react-router-dom";
    
    import { Routes } from "../routes";
    import ThemesbergLogo from "../assets/img/themesberg.svg";
    import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
    import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
    
    import NavItem from "./NavItem";
    import NavGroup from "./NavGroup";
    
    class Sidebar extends Component {
      constructor(props) {
        super(props);
        this.state = { show: false, showClass: "" };
        this.onCollapse = this.onCollapse.bind(this);
        this.setShow = this.setShow.bind(this);
      }
    
      onCollapse = () => {
        let bol=true
        if (this.state.show){
          bol=false;
        }
        this.setShow(bol);
      };
      setShow = (bol) => {
        this.setState({ show: bol });
      };
      render() {
        return (
          <>
            <Navbar
              expand={false}
              collapseOnSelect
              variant="dark"
              className="navbar-theme-primary px-4 d-md-none"
            >
              <Navbar.Brand
                className="me-lg-5"
                as={Link}
                to={Routes.DashboardOverview.path}
              >
                <Image src={ReactHero} className="navbar-brand-light" />
              </Navbar.Brand>
              <Navbar.Toggle
                as={Button}
                aria-controls="main-navbar"
                onClick={this.onCollapse}
              >
                <span className="navbar-toggler-icon" />
              </Navbar.Toggle>
            </Navbar>
            <CSSTransition
              timeout={300}
              in={this.state.show}
              classNames="sidebar-transition"
            >
              <SimpleBar
                className={`collapse ${this.state.showClass} sidebar d-md-block bg-primary text-white`}
              >
                <div className="sidebar-inner px-4 pt-3">
                  <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                    <div className="d-flex align-items-center">
                      <div className="user-avatar lg-avatar me-4">
                        <Image
                          src={ProfilePicture}
                          className="card-img-top rounded-circle border-white"
                        />
                      </div>
                      <div className="d-block">
                        <h6>Hi, Jane</h6>
                        <Button
                          as={Link}
                          variant="secondary"
                          size="xs"
                          to={Routes.Signin.path}
                          className="text-dark"
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                          Sign Out
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Nav.Link
                    className="collapse-close d-md-none"
                    onClick={this.onCollapse}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Nav.Link>
                  <Nav className="flex-column pt-3 pt-md-0">
                    {/* <p>santosh Bhagat</p> */}
                    <NavItem
                      buttonClick={this.setShow}
                      title="Perimeter"
                      link="/"
                      image={ReactHero}
                    />
                    <NavItem
                      buttonClick={this.setShow}
                      title="Overview"
                      link="/dashboard"
                      icon={faBook}
                    />
                    <NavGroup eventKey="tables/" title={t("user_management")} icon={faTable}><NavItem
        buttonClick={this.setShow}
        title={t("user")}
        link={Routes.User.pathview}
        icon={faBook}
      /><NavItem
        buttonClick={this.setShow}
        title={t("user_details")}
        link={Routes.UserDetails.pathview}
        icon={faBook}
      /><NavItem
        buttonClick={this.setShow}
        title={t("token")}
        link={Routes.Token.pathview}
        icon={faBook}
      /></NavGroup><NavGroup eventKey="tables/" title={t("masters")} icon={faTable}><NavItem
        buttonClick={this.setShow}
        title={t("quotes")}
        link={Routes.Quotes.pathview}
        icon={faBook}
      /><NavItem
        buttonClick={this.setShow}
        title={t("awards")}
        link={Routes.Awards.pathview}
        icon={faBook}
      /><NavItem
        buttonClick={this.setShow}
        title={t("client_says")}
        link={Routes.ClientSays.pathview}
        icon={faBook}
      /><NavItem
        buttonClick={this.setShow}
        title={t("client")}
        link={Routes.Client.pathview}
        icon={faBook}
      /><NavItem
        buttonClick={this.setShow}
        title={t("values")}
        link={Routes.Values.pathview}
        icon={faBook}
      />
      <NavItem
        buttonClick={this.setShow}
        title={t("team")}
        link={Routes.Team.pathview}
        icon={faBook}
      />
      <NavItem
        buttonClick={this.setShow}
        title={t("work")}
        link={Routes.Work.pathview}
        icon={faBook}
      />
      <NavItem
        buttonClick={this.setShow}
        title={t("recent-work")}
        link={Routes.RecentWork.pathview}
        icon={faBook}
      />
      <NavItem
        buttonClick={this.setShow}
        title={t("contact-us")}
        link={Routes.ContactUs.pathview}
        icon={faBook}
      />
      </NavGroup>
                </Nav>
                </div>
              </SimpleBar>
            </CSSTransition>
          </>
        );
      }
    }
    
    export default Sidebar;
    