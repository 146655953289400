export const GetData = (key) => {
  let lang = localStorage.getItem("language");
  // console.log("Language", lang);
  if (!lang) {
    localStorage.setItem("language", "en");
    lang = "en";
  }
  // const lang = "hindi";

  // let value = key.charAt(0).toUpperCase() + key.slice(1);
  let value = key.replace(/\s+/g, "_").toLowerCase();
  key = value;
  let data = require("./" + lang + ".json");
  // const data = langualData[lang];
  if (!data[key] || data[key] === undefined) {
    data = require("./en.json");
  }
  if (data[key]) {
    value = data[key];
  }

  // console.log(data[key]);
  return value;
};
