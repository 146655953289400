import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils';

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Preloader from "./Preloader";


const PrivateRoute = ({component: Component, ...rest}) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
      }, []);
    
      const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
      }
    
      const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);
    
      const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
      }
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            
                isLogin()?
                <>
                <Preloader show={loaded ? false : true} />
                 <Sidebar />
                 <main className="content">
                <Navbar />
                <Component {...props} />
                <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
                
                </main>
                </>
                :
                <Redirect to="/login" />
           

        
        )} 
        
        />
    );
};

export default PrivateRoute;