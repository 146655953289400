import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";

import { GetData as t } from "../../lingual";
import { Routes } from "../../routes";
import { Card } from "@themesberg/react-bootstrap";
import "./Work.less";
import { notification, Form, Input, Button, Switch, Spin, Upload  } from "antd";

import LocalStorageService from "../../services/storage/LocalStorageService";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import axios from "../../axios";

import { logout } from "../../utils";

const { TextArea } = Input;


class WorkAddEdit extends Component {
  constructor(props) {
    super(props);

    const localStorageService = LocalStorageService.getService();
    

    const token = localStorageService.getAccessToken();
    const props1 = {
      name: "image",
      action: BASE_URL + "/uploadImage",
      headers: {
        authorization: "authorization-text",
        "x-access-token": token,
      },
    };

    this.state = {
      title: t("work"),
      description: t("add_new_work"),
      columns: [],
      values: { status: true },
      gotData: false,
      active: true,
      submitButtonName: t("submit"),
      uploadprops: props1,
      uploadvaluepic_square: "",
      uploadvaluepic_wide: "",
      uploadvaluepic_inner: "",
      uploadvaluepic_innervideo: "",
      uploadvaluepic_innervideotwo: "",
      uploadvaluepic_innervideothree: "",
      uploadvaluepic_innervideofour: "",
      uploadvaluepic_innervideofive: "",
      htmleditervaluehtml: "",
    };

    this.onValuesChange = this.onValuesChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
    this.switchOnChange = this.switchOnChange.bind(this);

    this.uploadOnChangepic_square = this.uploadOnChangepic_square.bind(this);
    this.uploadOnChangepic_wide = this.uploadOnChangepic_wide.bind(this);
    this.uploadOnChangepic_inner = this.uploadOnChangepic_inner.bind(this);
    this.uploadOnChangepic_innervideo = this.uploadOnChangepic_innervideo.bind(this);
    this.uploadOnChangepic_innervideotwo = this.uploadOnChangepic_innervideotwo.bind(this);
    this.uploadOnChangepic_innervideothree = this.uploadOnChangepic_innervideothree.bind(this);
    this.uploadOnChangepic_innervideofour = this.uploadOnChangepic_innervideofour.bind(this);
    this.uploadOnChangepic_innervideofive = this.uploadOnChangepic_innervideofive.bind(this);
    this.htmlediterOnChangehtml = this.htmlediterOnChangehtml.bind(this);
  }

  uploadOnChangepic_square = (value) => {
    console.log(this.state.uploadvaluepic_square, "pic_square");
    this.setState({ uploadvaluepic_square: "" });
  };
  uploadOnChangepic_wide = (value) => {
    console.log(this.state.uploadvaluepic_wide, "pic_wide");
    this.setState({ uploadvaluepic_wide: "" });
  };

  uploadOnChangepic_inner = (value) => {
    console.log(this.state.uploadvaluepic_inner, "");
    this.setState({ uploadvaluepic_inner: "" });
  };
  uploadOnChangepic_innervideo = (value) => {
    console.log(this.state.uploadvaluepic_innervideo, "");
    this.setState({ uploadvaluepic_innervideo: "" });
  };
  uploadOnChangepic_innervideotwo = (value) => {
    console.log(this.state.uploadvaluepic_innervideotwo, "");
    this.setState({ uploadvaluepic_innervideotwo: "" });
  };
  uploadOnChangepic_innervideothree = (value) => {
    console.log(this.state.uploadvaluepic_innervideothree, "");
    this.setState({ uploadvaluepic_innervideothree: "" });
  };

  uploadOnChangepic_innervideofour = (value) => {
    console.log(this.state.uploadvaluepic_innervideofour, "");
    this.setState({ uploadvaluepic_innervideofour: "" });
  };

  uploadOnChangepic_innervideofive = (value) => {
    console.log(this.state.uploadvaluepic_innervideofive, "");
    this.setState({ uploadvaluepic_innervideofive: "" });
  };


  htmlediterOnChangehtml = (value) => {
    this.setState({ htmleditervaluehtml: value });
  };
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  formRef = React.createRef();
  onValuesChange = (d) => {};

  onFinish = (values) => {
    values.status = this.state.active ? 1 : 0;

    console.log(this.state.uploadvaluepic_square);
    if (this.state.uploadvaluepic_square != "") {
      values.pic_square = this.state.uploadvaluepic_square;
    } else {
      values.pic_square = values.pic_square?.file.response.filename;
    }
    console.log(this.state.uploadvaluepic_wide);
    if (this.state.uploadvaluepic_wide != "") {
      values.pic_wide = this.state.uploadvaluepic_wide;
    } else {
      values.pic_wide = values.pic_wide?.file.response.filename;
    }

    if (this.state.uploadvaluepic_inner != "") {
      values.inner_image_url = this.state.uploadvaluepic_inner;
    }  else {
      values.inner_image_url = values.inner_image_url?.file.response.filename;
    }

    if (this.state.uploadvaluepic_innervideo != "") {
      values.inner_video_link1_image = this.state.uploadvaluepic_innervideo;
    }  else {
      values.inner_video_link1_image = values.inner_video_link1_image?.file.response.filename;
    }

    if (this.state.uploadvaluepic_innervideotwo != "") {
      values.inner_video_link2_image = this.state.uploadvaluepic_innervideotwo;
    }  else {
      values.inner_video_link2_image = values.inner_video_link2_image?.file.response.filename;
    }

    if (this.state.uploadvaluepic_innervideothree != "") {
      values.inner_video_link3_image = this.state.uploadvaluepic_innervideothree;
    }  else {
      values.inner_video_link3_image = values.inner_video_link3_image?.file.response.filename;
    }

    if (this.state.uploadvaluepic_innervideofour != "") {
      values.inner_video_link4_image = this.state.uploadvaluepic_innervideofour;
    }  else {
      values.inner_video_link4_image = values.inner_video_link4_image?.file.response.filename;
    }

    if (this.state.uploadvaluepic_innervideofive != "") {
      values.inner_video_link5_image = this.state.uploadvaluepic_innervideofive;
    }  else {
      values.inner_video_link5_image = values.inner_video_link5_image?.file.response.filename;
    }

    values.html = this.state.htmleditervaluehtml;

    let path = "work";
    if (this.props.match.params.id === "new") {
      axios
        .post(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("work"),
                res.data.error.message
              );
            } else {
              this.cancelFun();
              this.openNotificationWithIcon(
                "success",
                t("work"),
                t("data_added_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      path += "/" + this.props.match.params.id;
      axios
        .put(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                "Work",
                res.data.error.message
              );
            } else {
              this.cancelFun();
              this.openNotificationWithIcon(
                "success",
                "Work",
                t("data_updated_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    }
  };
  onFinishFailed = (values) => {};

  switchOnChange = (checked) => {
    this.setState({ active: checked });
  };
  cancelFun = () => {
    // {pathname:this.props.history.goBack,state:this.props.location.state}
    this.props.history.push({
      pathname: Routes.Work.pathview,
      state: this.props.location.state,
    });
  };

  componentDidMount(prevProps) {
    if (this.props.match.params.id !== "new") {
      this.setState({
        description: t("edit_work"),
        submitButtonName: t("update"),
      });

      let path = "work/" + this.props.match.params.id;
      axios
        .get(path)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("role_permission"),
                res.data.error.message
              );
            } else {
              console.log(res.data.data);
              this.setState({
                values: res.data.data,

                gotData: true,
                active: res.data.data.status,
              });
              this.setState({
                uploadvaluepic_square: res.data.data.pic_square,
              });
              this.setState({ uploadvaluepic_wide: res.data.data.pic_wide });
              this.setState({ uploadvaluepic_inner: res.data.data.inner_image_url });
              this.setState({ uploadvaluepic_innervideo: res.data.data.inner_video_link1_image });
              this.setState({ uploadvaluepic_innervideotwo: res.data.data.inner_video_link2_image });
              this.setState({ uploadvaluepic_innervideothree: res.data.data.inner_video_link3_image });
              this.setState({ uploadvaluepic_innervideofour: res.data.data.inner_video_link4_image });
              this.setState({ uploadvaluepic_innervideofive: res.data.data.inner_video_link5_image });
              this.setState({ htmleditervaluehtml: res.data.data.html });
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      this.setState({ gotData: true });
    }
  }

  render() {
    return (
      <>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{this.state.title}</h4>
            <p className="mb-0">{this.state.description}</p>
          </div>
        </div>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            {this.state.gotData ? (
              <Form
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={this.state.values}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                size="default"
                ref={this.formRef}
              >
                <Form.Item label={t("id")} key="100" name="work_id">
                  <Input placeholder={t("auto_incerment")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("project_name")}
                  key="0"
                  name="project_name"
                  rules={[
                    { required: 0, message: t("please_enter_a_project_name") },
                  ]}
                >
                  <Input placeholder={t("enter_a_project_name")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("client_type")}
                  key="1"
                  name="client_type"
                  rules={[
                    { required: 0, message: t("please_enter_a_client_type") },
                  ]}
                >
                  <Input placeholder={t("enter_a_client_type")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("description")}
                  key="2"
                  name="description"
                  rules={[
                    { required: 0, message: t("please_enter_a_description") },
                  ]}
                >
                  <Input placeholder={t("enter_a_description")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("client_name")}
                  key="3"
                  name="client_name"
                  rules={[
                    { required: 0, message: t("please_enter_a_client_name") },
                  ]}
                >
                  <Input placeholder={t("enter_a_client_name")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("branding")}
                  key="4"
                  name="branding"
                  rules={[
                    { required: 0, message: t("please_enter_a_branding") },
                  ]}
                >
                  <Input placeholder={t("enter_a_branding")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("website")}
                  key="5"
                  name="website"
                  rules={[{ required: 0, message: t("please_enter__website") }]}
                >
                  <Input placeholder={t("enter_a_website")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("campaigns")}
                  key="6"
                  name="campaigns"
                  rules={[
                    { required: 0, message: t("please_enter__campaign") },
                  ]}
                >
                  <Input placeholder={t("enter_a_campaign")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("context")}
                  key="7"
                  name="context"
                  rules={[{ required: 0, message: t("please_enter__context") }]}
                >
                  <TextArea placeholder={t("enter_a_context")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("solution")}
                  key="8"
                  name="solution"
                  rules={[
                    { required: 0, message: t("please_enter__solution") },
                  ]}
                >
                  <TextArea placeholder={t("enter_a_solution")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("result")}
                  key="9"
                  name="result"
                  rules={[{ required: 0, message: t("please_enter__result") }]}
                >
                  <TextArea placeholder={t("enter_a_result")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("picture_square")}
                  key="10"
                  name="pic_square"
                  rules={[
                    { required: 0, message: t("picture_square_is_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_square}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label={t("wide_picture")}
                  key="11"
                  name="pic_wide"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_wide}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label={t("html")} key="12">
                  <ReactQuill
                    theme="snow"
                    value={this.state.htmleditervaluehtml}
                    onChange={this.htmlediterOnChangehtml}
                  />
                </Form.Item>
                <Form.Item
                  label={t("facebook")}
                  key="13"
                  name="facebook"
                  rules={[{ required: 0, message: t("facebook_is_required") }]}
                >
                  <Input placeholder={t("enter_a_facebook")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("twitter")}
                  key="14"
                  name="twitter"
                  rules={[{ required: 0, message: t("facebook_twitter") }]}
                >
                  <Input placeholder={t("enter_a_twitter")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("linkedin")}
                  key="15"
                  name="linkedin"
                  rules={[{ required: 0, message: t("linkedin_is_required") }]}
                >
                  <Input placeholder={t("enter_a_linkedin")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("show_reel")}
                  key="16"
                  name="reel"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_a_show_reel")} disabled="" />
                </Form.Item>


                <Form.Item
                  label={t("immer image")}
                  key="11"
                  name="inner_video_link4_image"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_innervideofour}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>

                 <Form.Item
                  label={t("Innet Image Header")}
                  key="16"
                  name="inner_video_link4"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_a_show_reel")} disabled="" />
                </Form.Item>


                <Form.Item
                  label={t("Inner Image")}
                  key="11"
                  name="inner_video_link5_image"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_innervideofive}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  label={t("Innet Image Header")}
                  key="16"
                  name="inner_video_link5"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_a_show_reel")} disabled="" />
                </Form.Item>




                <Form.Item
                  label={t("Inner Image")}
                  key="11"
                  name="inner_image_url"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_inner}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  label={t("Innet Image Header")}
                  key="16"
                  name="inner_image_header"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_image_header")} disabled="" />
                </Form.Item>



                <Form.Item
                  label={t("inner_video_image1")}
                  key="11"
                  name="inner_video_link1_image"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_innervideo}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>



                <Form.Item
                  label={t("inner_video_link1")}
                  key="16"
                  name="inner_video_link1"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_a_show_reel")} disabled="" />
                </Form.Item>



                <Form.Item
                  label={t("inner_video_image2")}
                  key="11"
                  name="inner_video_link2_image"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_innervideotwo}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>


                <Form.Item
                  label={t("inner_video_link2")}
                  key="16"
                  name="inner_video_link2"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_a_show_reel")} disabled="" />
                </Form.Item>


                <Form.Item
                  label={t("inner_video_image3")}
                  key="11"
                  name="inner_video_link3_image"
                  rules={[
                    { required: 0, message: t("wide_pictureis_required") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepic_innervideothree}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  label={t("inner_video_link3")}
                  key="16"
                  name="inner_video_link3"
                  rules={[{ required: 0, message: t("show_reel_is_required") }]}
                >
                  <Input placeholder={t("enter_a_show_reel")} disabled="" />
                </Form.Item>


               
                <Form.Item label={t("active")} key="101">
                  <Switch
                    name="status"
                    key="101"
                    onChange={this.switchOnChange}
                    defaultChecked={this.state.values.status}
                  />
                </Form.Item>
                <Form.Item justify="center">
                  <Button className="paddme" onClick={this.cancelFun}>
                    {t("cancel")}
                  </Button>

                  <Button type="primary" htmlType="submit">
                    {this.state.submitButtonName}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <div className="loadingpsin">
                <Spin />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default WorkAddEdit;
