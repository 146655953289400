import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../../utils";

import { Routes } from "../../routes";
import axios from "../../axios";

import { notification } from "antd";
import { GetData as t } from "../../lingual";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      repassword: "",
      message: "",
      showPassword: false,
    };
    // this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleRePasswordChange = this.handleRePasswordChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleClickShowPassword(e) {
    console.log("handleClickShowPassword", this.state.showPassword);
    this.setState({ showPassword: !this.state.showPassword });
    console.log("handleClickShowPassword", this.state.showPassword);
  }
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  componentDidMount(prevProps) {
    let data = {
      token: this.props.match.params.token,
    };

    axios
      .post(`auth/verifytoken`, data)
      .then((res) => {
        if (res.status === 200) {
          console.log("Login ", res.data);
          if (res.data.error) {
            this.props.history.push("/login");
            this.openNotificationWithIcon(
              "warning",
              t("password_recovery"),
              res.data.error.message
            );
          } else {
            this.setState({ email: res.data.email });
          }
          //this.setState({success:true,message:"Reset link has been send to your email."});
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.openNotificationWithIcon(
            "warning",
            t("invalid_login"),
            t("please_try_again")
          );

          logout();
          this.props.history.push("/login");
        } else {
          this.openNotificationWithIcon(
            "warning",
            t("something_went_wrong"),
            t("please_contact_adminstrator")
          );
        }
      });
  }

  // handleEmailChange(event) { this.setState({ email: event.target.value }); }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleRePasswordChange(event) {
    this.setState({ repassword: event.target.value });
  }

  handleSubmit = (event) => {
    if (this.state.password === this.state.repassword) {
      let data = {
        token: this.props.match.params.token,
        password: this.state.password,
      };

      axios
        .post(`auth/change-password`, data)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "error",
                t("password_recovery"),
                res.data.error.message
              );
            } else {
              this.props.history.push("/login");
              this.openNotificationWithIcon(
                "success",
                t("password_recovery"),
                res.data.message
              );
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );

            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      this.setState({ message: t("passwrd_not_match") });
    }
    event.preventDefault();
  };

  render() {
    return (
      <main>
        <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center">
              <p className="text-center">
                <Card.Link
                  as={Link}
                  to={Routes.Signin.path}
                  className="text-gray-700"
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" />{" "}
                  {t("back_sign_in")}
                </Card.Link>
              </p>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <h3 className="mb-4"> {t("reset_password")}</h3>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>{t("your_email")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="email"
                          disabled
                          placeholder={t("email_placeholder")}
                          value={this.state.email}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>{t("your_password")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          type={this.state.showPassword ? "text" : "password"}
                          onChange={this.handlePasswordChange}
                          placeholder={t("password_placeholder")}
                          value={this.state.password}
                        />
                        <InputGroup.Text className="handCursor">
                          <FontAwesomeIcon
                            onClick={this.handleClickShowPassword}
                            icon={this.state.showPassword ? faEyeSlash : faEye}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group id="confirmPassword" className="mb-4">
                      <Form.Label>{t("confirm_password")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          onChange={this.handleRePasswordChange}
                          placeholder={t("confirm_password")}
                          value={this.state.repassword}
                        />
                      </InputGroup>
                    </Form.Group>
                    {this.state.message !== "" ? (
                      <p>{this.state.message}</p>
                    ) : null}

                    <Button variant="primary" type="submit" className="w-100">
                      {t("reset_password")}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
}

export default withRouter(ResetPassword);
