import React, { Component } from "react";

import { Form, Select, notification } from "antd";

import axios from "../axios";

import { logout } from "../utils";

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], goData: true };
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  componentDidMount(prevProps) {
    axios
      .get(this.props.dataSource)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.error) {
            this.openNotificationWithIcon(
              "warning",
              "Licence Data",
              res.data.error.message
            );
          } else {
            this.setState({ data: res.data.data, gotData: true });
          }
        } else {
          this.openNotificationWithIcon(
            "warning",
            "Something went wrong!",
            "Please contact adminstrator!"
          );
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.openNotificationWithIcon(
            "warning",
            "Invalid Login!",
            "Please try again!"
          );
          logout();
          this.props.history.push("/login");
        } else {
          this.openNotificationWithIcon(
            "warning",
            "Something went wrong!",
            "Please contact adminstrator!"
          );
        }
      });
  }

  render() {
    return (
      <>
        {this.state.goData ? (
          <Form.Item
            label={this.props.label}
            key={this.props.key}
            name={this.props.name}
            rules={this.props.rules}
          >
            <Select placeholder={this.props.placeholder} mode={this.props.mode}>
              {this.state.data.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
      </>
    );
  }
}

export default SelectComponent;
