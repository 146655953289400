import React, { Component } from "react";
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faHome } from "@fortawesome/free-solid-svg-icons";
    import { Breadcrumb } from "@themesberg/react-bootstrap";

    import { GetData as t } from "../../lingual";
    import { Routes } from "../../routes";
    import { Card } from "@themesberg/react-bootstrap";
    import "./Quotes.less";
    import {
      notification, Form,
      Input,
      Button,
      Switch,
      Spin,
      
      
    } from "antd";
    
    
    import axios from "../../axios";
    
    import { logout } from "../../utils";

    
    class QuotesAddEdit extends Component {
    
      constructor(props) {
        super(props);

        

        this.state = {
          title: t("quotes"),
          description: t("add_quotes"),
          columns: [],
          values: {status:true},
          gotData: false,
          active: true,
          submitButtonName:t("submit"),
          
        }
        
        this.onValuesChange = this.onValuesChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.switchOnChange = this.switchOnChange.bind(this);

        

      }

      
      openNotificationWithIcon = (type, message, description) => {
        notification[type]({
          message: message,
          description: description,
        });
      };
      formRef = React.createRef();
      onValuesChange = (d) => {
        
      }
    
      onFinish = (values) => {
        
        values.status = this.state.active?1:0;
        
    
        
        let path="quotes";
        if (this.props.match.params.id==="new"){
          
        
        axios.post(path, values)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              if (res.data.error) {
    
                this.openNotificationWithIcon("warning",t("quotes"), res.data.error.message)
              } else {
                this.cancelFun();
                this.openNotificationWithIcon("success",t("quotes"), t("data_added_successfully"));
              }
            }
          }).catch(error => {
            console.log(error)
            if (error.response && error.response.status === 401) {
              this.openNotificationWithIcon("warning",t("invalid_login"),t("please_try_again"));
              logout();
              this.props.history.push("/login");
            } else {
              this.openNotificationWithIcon("warning",t("something_went_wrong") , t("please_contact_adminstrator"))
            }
          })
        }else {
          path += "/"+this.props.match.params.id;
          axios.put(path, values)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              if (res.data.error) {
    
                this.openNotificationWithIcon("warning", "Quotes", res.data.error.message)
              } else {
                this.cancelFun();
                this.openNotificationWithIcon("success", "Quotes", t("data_updated_successfully"));
              }
            }
          }).catch(error => {
            console.log(error)
            if (error.response && error.response.status === 401) {
              this.openNotificationWithIcon("warning",t("invalid_login"),t("please_try_again"));
              logout();
              this.props.history.push("/login");
            } else {
              this.openNotificationWithIcon("warning",t("something_went_wrong") , t("please_contact_adminstrator"))
            }
          })
    
        }
      };
      onFinishFailed = (values) => {
      };
    
      switchOnChange = (checked) => {
        this.setState({ active: checked })
      };
      cancelFun = () => {
        // {pathname:this.props.history.goBack,state:this.props.location.state}
        this.props.history.push({
          pathname: Routes.Quotes.pathview,
          state: this.props.location.state,
        });
      };
    
      componentDidMount(prevProps) {
        
        if (this.props.match.params.id!=="new"){
          this.setState({
            description: t("edit_quotes"),
            submitButtonName: t("update"),
          });

          let path = "quotes/"+this.props.match.params.id;
          axios
            .get(path)
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                if (res.data.error) {
                  
                  this.openNotificationWithIcon(
                    "warning",
                    t("role_permission"),
                    res.data.error.message
                  );
                } else {
                  console.log(res.data.data);
                  this.setState({
                    values: res.data.data,
                    
                    gotData: true,
                    active: res.data.data.status,});
                  
                }
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                this.openNotificationWithIcon(
                  "warning",
                  t("invalid_login"),
                  t("please_try_again")
                );
                logout();
                this.props.history.push("/login");
              } else {
                this.openNotificationWithIcon(
                  "warning",
                  t("something_went_wrong"),
                  t("please_contact_adminstrator")
                );
              }
            });
        }else {
          this.setState({ gotData: true });
        }
      }
    
      render() {return (
          <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-xl-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
                </Breadcrumb>
                <h4>{this.state.title}</h4>
                <p className="mb-0">
                {this.state.description}
                </p>
              </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                {this.state.gotData ? (
                  <Form
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={this.state.values}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                size="default"
                ref={this.formRef}
              >
              <Form.Item
    label= {t("id")}
    key="100"
    name="quotes_id"
  >
    <Input
      placeholder= {t("auto_incerment")}
      disabled="true"
    />
  </Form.Item>
  <Form.Item
                  label= {t("quotes")}
                  key="0"
                  name="quotes"
                  rules={
                    [
                      {required : 1,          
                          message: t("quotes__is_required")}

                  ]
                }
                >
                  <Input
                    placeholder= {t("enter_quotes")}
                    disabled=""
                  />
                </Form.Item>
                <Form.Item
                      
                      label={t("active")}
                      key="101"
                    >
                      <Switch
                        name="status"
                        key="101"
                        onChange={this.switchOnChange}
                        defaultChecked={this.state.values.status}
                      />
                    </Form.Item>
    <Form.Item justify="center">
    <Button
      className="paddme"
      onClick={this.cancelFun}
    >
      {t("cancel")}
    </Button>

    <Button type="primary" htmlType="submit">
      {this.state.submitButtonName}
    </Button>
  </Form.Item>

              </Form>
                ) : (<div className="loadingpsin">
                  <Spin />
                </div>)}
              </Card.Body>
            </Card>
          </>
        );
      }
    }
    
    
    export default QuotesAddEdit;
    
    