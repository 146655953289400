const TOKEN_KEY = 'access_token';
const ROLE_KEY = 'user_role';


export const login = () => {
    localStorage.setItem(TOKEN_KEY, 'TestLogin');
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ROLE_KEY);
}

export const isLogin = () => {
    // console.log(localStorage.getItem(TOKEN_KEY))
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}
