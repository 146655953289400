import React, { Component } from "react";
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faHome } from "@fortawesome/free-solid-svg-icons";
    import { Breadcrumb } from "@themesberg/react-bootstrap";
    
    import { Card } from "@themesberg/react-bootstrap";
    
    import "./RolePermissions.less";
    import { Table, Tag, Space, Popconfirm, notification, Spin, Button } from "antd";
    
    import axios from "../../axios";
      
    import { logout } from "../../utils";

    import { Routes } from "../../routes";
    import { GetData as t } from "../../lingual";
    
    class RolePermissions extends Component {
  
    constructor(props) {
      super(props);
      this.state = { pagination: {
        current: 1,
        total: 10,
        pageSize: 10,
      },
      loading: false,
        gotData: false,
        title: t("role_permissions"),
        description: t("list_of_user_role_and_permission"),
        columns: [
          {
      title: t('id'),
      dataIndex: 'roles_id',
      key: 'roles_id',
    },{
        title: t('role_name'),
        dataIndex: 'role_name',
        key: 'role_name',
        ellipsis: {
          showTitle: true,
        }
      },{

      title: t("status"),
      key: "status",
      dataIndex: "status",
      type: "status",
      render: status => (
        <>
          {
            status ? (<Tag color="green" key={this.generateKey("c")}>
              {t("active")}
            </Tag>) : (<Tag color="volcano" key={this.generateKey("i")}>
            {t("inactive")}
            </Tag>)

          }
        </>
      )
    },{
  title: "Action",
  key: "action",
  render: (text, record) => (
    <Space size="middle">
      <span onClick={() => this.editItem(record.roles_id)} >Edit</span>
      <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteItem(record.roles_id)}>
        <span >Delete</span>
      </Popconfirm>

    </Space>
  )
}
        ],
        data: [
        ]
      }
    
      this.deleteItem = this.deleteItem.bind(this);
      this.editItem = this.editItem.bind(this);
      this.addNew = this.addNew.bind(this);
      this.generateKey=this.generateKey.bind(this);
      this.populateList=this.populateList.bind(this);
    }
  
    deleteItem = (item_id) => {
      console.log(item_id, "Delete Item");
      let path = "roles/"+item_id;
      let values={status:3};
      axios.delete(path, values)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              if (res.data.error) {
    
                this.openNotificationWithIcon("warning", "Role Permissions", res.data.error.message)
              } else {
                
                this.openNotificationWithIcon("success", "Role Permissions",t("data_deleted_successfully"));
                this.populateList();
              }
            }
          }).catch(error => {
            console.log(error)
            if (error.response && error.response.status === 401) {
              this.openNotificationWithIcon("warning", t("invalid_login"), t("please_try_again"));
              logout();
              this.props.history.push("/login");
            } else {
              this.openNotificationWithIcon("warning",t("something_went_wrong"),t("please_contact_adminstrator"));
            }
          })

    };
  
    addNew = ()=>{
      this.props.history.push(Routes.RolePermissions.addnew);
    }
  
    editItem = (item_id) => {
      this.props.history.push(Routes.RolePermissions.pathview+"/"+ item_id);
    };
    
    generateKey = (pre)=>{
      return `${pre}_${new Date().getTime()}`;
    }
  
    openNotificationWithIcon = (type, message, description) => {
      notification[type]({
        message: message,
        description: description,
      });
    };

    populateList =(page=1)=>{
      this.setState({ loading: true });
      let path = "roles/page/"+page;
              axios.get(path)
                .then(res => {
                  console.log(res);
                  if (res.status === 200) {
                    console.log("Login ", res.data);
                    if (res.data.error) {
                      // this.props.history.push("/login");
                      this.openNotificationWithIcon("warning", t("role_permissions"), res.data.error.message)
                    } else {
                      console.log(res.data.data,"Data")
                      this.setState({  data: res.data.data, gotData: true,loading:false, pagination: res.data.pagination, });
                    }
                  }
                }).catch(error => {
                  if (error.response && error.response.status === 401) {
                    this.openNotificationWithIcon("warning", t("invalid_login"), t("please_try_again"));

                    logout();
                    this.props.history.push("/login");
                  } else {
                    console.log(error)
                    this.openNotificationWithIcon("warning",t("something_went_wrong"),t("please_contact_adminstrator"));

                  }
                })
    }
  
    componentDidMount(prevProps) {
      this.populateList();
    }
    handleTableChange = (pagination, filters, sorter) => {
      this.populateList(pagination.current);
    };
  
    render() {
      const { pagination, loading } = this.state;
      return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-xl-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
                </Breadcrumb>
                <h4>{this.state.title}</h4>
                <p className="mb-0">
                  {this.state.description}
                </p>
              </div>
            </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            {this.state.gotData ? (<div><Button type="primary" className="buttonNew" onClick={this.addNew} >{t("add_new")}</Button>
              
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={pagination} loading={loading} onChange={this.handleTableChange} /></div>
              ) : (<div className="loadingpsin">
                <Spin />
              </div>)}
            </Card.Body>
          </Card>
        </>
      );
    }
  }
  export default RolePermissions;
  