// LocalStorageService.js
const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    // console.log(tokenObj.roles[0])
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("user_role", tokenObj.roles[0]);
    // localStorage.setItem("user_type", tokenObj.user_type[0]);
    localStorage.setItem("user_name", tokenObj.username);
    localStorage.setItem("user_picture", tokenObj.user_picture);
  }
  function _getAccessToken() {
    return localStorage.getItem("access_token");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function _clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_role");
    localStorage.removeItem("user_type");
  }
  function _setLanguage(language) {
    localStorage.setItem("language", language);
  }
  function _getLanguage(language) {
    return localStorage.getItem("language");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setLanguage: _setLanguage,
    getLanguage: _getLanguage,
  };
})();

export default LocalStorageService;
