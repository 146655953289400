import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";

import { GetData as t } from "../../lingual";
import { Routes } from "../../routes";
import { Card } from "@themesberg/react-bootstrap";
import "./Team.less";
import {
  notification,
  Form,
  Input,
  Button,
  Switch,
  Spin,
  Upload,
  Space,
} from "antd";
import LocalStorageService from "../../services/storage/LocalStorageService";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../config";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "../../axios";

import { logout } from "../../utils";
// import TextArea from "rc-textarea";

class TeamAddEdit extends Component {
  constructor(props) {
    super(props);

    const localStorageService = LocalStorageService.getService();

    const token = localStorageService.getAccessToken();
    const props1 = {
      name: "image",
      action: BASE_URL + "/uploadImage",
      headers: {
        authorization: "authorization-text",
        "x-access-token": token,
      },
    };

    this.state = {
      title: t("team"),
      description: t("add_new_team"),
      columns: [],
      values: { status: true },
      gotData: false,
      active: true,
      submitButtonName: t("submit"),
      uploadprops: props1,
      uploadvaluepicture: "",
    };

    this.onValuesChange = this.onValuesChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
    this.switchOnChange = this.switchOnChange.bind(this);

    this.uploadOnChangepicture = this.uploadOnChangepicture.bind(this);
  }

  uploadOnChangepicture = (value) => {
    console.log(this.state.uploadvaluepicture, "picture");
    this.setState({ uploadvaluepicture: "" });
  };
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  formRef = React.createRef();
  onValuesChange = (d) => {};

  onFinish = (values) => {
    values.status = this.state.active ? 1 : 0;

    console.log(this.state.uploadvaluepicture);
    if (this.state.uploadvaluepicture != "") {
      values.picture = this.state.uploadvaluepicture;
    } else {
      values.picture = values.picture.file.response.filename;
    }

    let path = "team";
    if (this.props.match.params.id === "new") {
      axios
        .post(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("team"),
                res.data.error.message
              );
            } else {
              this.cancelFun();
              this.openNotificationWithIcon(
                "success",
                t("team"),
                t("data_added_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      path += "/" + this.props.match.params.id;
      axios
        .put(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                "Team",
                res.data.error.message
              );
            } else {
              this.cancelFun();
              this.openNotificationWithIcon(
                "success",
                "Team",
                t("data_updated_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    }
  };
  onFinishFailed = (values) => {};

  switchOnChange = (checked) => {
    this.setState({ active: checked });
  };
  cancelFun = () => {
    // {pathname:this.props.history.goBack,state:this.props.location.state}
    this.props.history.push({
      pathname: Routes.Team.pathview,
      state: this.props.location.state,
    });
  };

  componentDidMount(prevProps) {
    if (this.props.match.params.id !== "new") {
      this.setState({
        description: t("edit_team"),
        submitButtonName: t("update"),
      });

      let path = "team/" + this.props.match.params.id;
      axios
        .get(path)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("role_permission"),
                res.data.error.message
              );
            } else {
              console.log(res.data.data);
              this.setState({
                values: res.data.data,

                gotData: true,
                active: res.data.data.status,
              });
              this.setState({ uploadvaluepicture: res.data.data.picture });
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      this.setState({ gotData: true });
    }
  }

  render() {
    const { TextArea } = Input;

    return (
      <>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{this.state.title}</h4>
            <p className="mb-0">{this.state.description}</p>
          </div>
        </div>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            {this.state.gotData ? (
              <Form
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={this.state.values}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                size="default"
                ref={this.formRef}
              >
                <Form.Item label={t("id")} key="100" name="team_id">
                  <Input placeholder={t("auto_incerment")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("team_member_name")}
                  key="0"
                  name="team_name"
                  rules={[
                    {
                      required: 1,
                      message: t("please_enter_a_team_member_name"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("enter_a_team_member_name")}
                    disabled=""
                  />
                </Form.Item>
                <Form.Item
                  label={t("designation")}
                  key="1"
                  name="designation"
                  rules={[
                    { required: 1, message: t("please_enter_a_designation") },
                  ]}
                >
                  <Input placeholder={t("enter_a_designation")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("describe_me")}
                  key="2"
                  name="describe"
                  rules={[
                    { required: 1, message: t("please_enter_a_describe_me") },
                  ]}
                >
                  <Input placeholder={t("enter_a_describe_me")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("picture")}
                  key="3"
                  name="picture"
                  rules={[
                    { required: 1, message: t("please_enter_a_picture") },
                  ]}
                >
                  <Upload
                    name={this.state.uploadprops.name}
                    action={this.state.uploadprops.action}
                    header={this.state.uploadprops.headers}
                    onChange={this.uploadOnChangepicture}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("click_to_upload")}
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  label={t("linkedin")}
                  key="4"
                  name="linkedin"
                  rules={[
                    { required: 1, message: t("please_enter_a_linkedin") },
                  ]}
                >
                  <Input placeholder={t("enter_a_linkedin")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("mail")}
                  key="5"
                  name="mail"
                  rules={[{ required: 1, message: t("please_enter_a_mail") }]}
                >
                  <Input placeholder={t("enter_a_mail")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("whatapps")}
                  key="6"
                  name="whatapps"
                  rules={[
                    { required: false, message: t("please_enter_a_whatapps") },
                  ]}
                >
                  <Input placeholder={t("enter_a_whatapps")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("about_word_1")}
                  key="7"
                  name="word1"
                  rules={[
                    {
                      required: false,
                      message: t("please_enter_a_about_word_1"),
                    },
                  ]}
                >
                  <Input placeholder={t("enter_a_about_word_1")} disabled="" />
                </Form.Item>
                <Form.Item
                  label={t("about_word_2")}
                  key="8"
                  name="word2"
                  rules={[
                    {
                      required: false,
                      message: t("please_enter_a_about_word_2"),
                    },
                  ]}
                >
                  <Input placeholder={t("enter_a_about_word_2")} disabled="" />
                </Form.Item>

                <Form.Item label={t("strength")} key="9">
                  <Form.List name="strength">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "title"]}
                              fieldKey={[fieldKey, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("title") + t("is_required"),
                                },
                              ]}
                            >
                              <Input placeholder={t("title")} />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              name={[name, "description"]}
                              fieldKey={[fieldKey, "description"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("description") + t("is_required"),
                                },
                              ]}
                            >
                              <TextArea
                                placeholder={t("description")}
                                rows={4}
                              />
                            </Form.Item>

                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            {t("add_strength")}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>

                <Form.Item label={t("active")} key="101">
                  <Switch
                    name="status"
                    key="101"
                    onChange={this.switchOnChange}
                    defaultChecked={this.state.values.status}
                  />
                </Form.Item>
                <Form.Item justify="center">
                  <Button className="paddme" onClick={this.cancelFun}>
                    {t("cancel")}
                  </Button>

                  <Button type="primary" htmlType="submit">
                    {this.state.submitButtonName}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <div className="loadingpsin">
                <Spin />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TeamAddEdit;
