import React, { Component } from "react";
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faHome } from "@fortawesome/free-solid-svg-icons";
    import { Breadcrumb } from "@themesberg/react-bootstrap";

    import { GetData as t } from "../../lingual";
    import { Routes } from "../../routes";
    import { Card } from "@themesberg/react-bootstrap";
    import "./ClientSays.less";
    import {
      notification, Form,
      Input,
      Button,
      Switch,
      Spin,
      Upload,
      
    } from "antd";
    import ReactQuill from "react-quill";
          import "react-quill/dist/quill.snow.css";import LocalStorageService from "../../services/storage/LocalStorageService";import { UploadOutlined } from "@ant-design/icons";import { BASE_URL } from "../../config";
    
    import axios from "../../axios";
    
    import { logout } from "../../utils";

    
    class ClientSaysAddEdit extends Component {
    
      constructor(props) {
        super(props);

        const localStorageService = LocalStorageService.getService();

        const token = localStorageService.getAccessToken();
        const props1 = {
              name: "image",
              action: BASE_URL + "/uploadImage",
              headers: {
                authorization: "authorization-text",
                "x-access-token": token,
              },
            };

        this.state = {
          title: t("client_says"),
          description: t("add_new_client_says"),
          columns: [],
          values: {status:true},
          gotData: false,
          active: true,
          submitButtonName:t("submit"),
          htmleditervalueclient_says: "",uploadprops: props1,uploadvalueclient_picture: "",
        }
        
        this.onValuesChange = this.onValuesChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onFinishFailed = this.onFinishFailed.bind(this);
        this.switchOnChange = this.switchOnChange.bind(this);

        this.htmlediterOnChangeclient_says = this.htmlediterOnChangeclient_says.bind(this);this.uploadOnChangeclient_picture = this.uploadOnChangeclient_picture.bind(this);

      }

      htmlediterOnChangeclient_says = (value) => {
            this.setState({ htmleditervalueclient_says: value })
          };uploadOnChangeclient_picture = (value) => {
            console.log(this.state.uploadvalueclient_picture,"client_picture");
            this.setState({ uploadvalueclient_picture: "" })
          };
      openNotificationWithIcon = (type, message, description) => {
        notification[type]({
          message: message,
          description: description,
        });
      };
      formRef = React.createRef();
      onValuesChange = (d) => {
        
      }
    
      onFinish = (values) => {
        
        values.status = this.state.active?1:0;
        
    
        console.log(this.state.uploadvalueclient_picture);
        if (this.state.uploadvalueclient_picture!=""){
          values.client_picture =this.state.uploadvalueclient_picture;
        }else {
        values.client_picture = values.client_picture.file.response.filename;}values.client_says = this.state.htmleditervalueclient_says;
        
        let path="clientsays";
        if (this.props.match.params.id==="new"){
          
        
        axios.post(path, values)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              if (res.data.error) {
    
                this.openNotificationWithIcon("warning",t("client_says"), res.data.error.message)
              } else {
                this.cancelFun();
                this.openNotificationWithIcon("success",t("client_says"), t("data_added_successfully"));
              }
            }
          }).catch(error => {
            console.log(error)
            if (error.response && error.response.status === 401) {
              this.openNotificationWithIcon("warning",t("invalid_login"),t("please_try_again"));
              logout();
              this.props.history.push("/login");
            } else {
              this.openNotificationWithIcon("warning",t("something_went_wrong") , t("please_contact_adminstrator"))
            }
          })
        }else {
          path += "/"+this.props.match.params.id;
          axios.put(path, values)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              if (res.data.error) {
    
                this.openNotificationWithIcon("warning", "Client Says", res.data.error.message)
              } else {
                this.cancelFun();
                this.openNotificationWithIcon("success", "Client Says", t("data_updated_successfully"));
              }
            }
          }).catch(error => {
            console.log(error)
            if (error.response && error.response.status === 401) {
              this.openNotificationWithIcon("warning",t("invalid_login"),t("please_try_again"));
              logout();
              this.props.history.push("/login");
            } else {
              this.openNotificationWithIcon("warning",t("something_went_wrong") , t("please_contact_adminstrator"))
            }
          })
    
        }
      };
      onFinishFailed = (values) => {
      };
    
      switchOnChange = (checked) => {
        this.setState({ active: checked })
      };
      cancelFun = () => {
        // {pathname:this.props.history.goBack,state:this.props.location.state}
        this.props.history.push({
          pathname: Routes.ClientSays.pathview,
          state: this.props.location.state,
        });
      };
    
      componentDidMount(prevProps) {
        
        if (this.props.match.params.id!=="new"){
          this.setState({
            description: t("edit_client_says"),
            submitButtonName: t("update"),
          });

          let path = "clientsays/"+this.props.match.params.id;
          axios
            .get(path)
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                if (res.data.error) {
                  
                  this.openNotificationWithIcon(
                    "warning",
                    t("role_permission"),
                    res.data.error.message
                  );
                } else {
                  console.log(res.data.data);
                  this.setState({
                    values: res.data.data,
                    
                    gotData: true,
                    active: res.data.data.status,});
            this.setState({ htmleditervalueclient_says: res.data.data.client_says });
            this.setState({ uploadvalueclient_picture: res.data.data.client_picture });
                  
                }
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                this.openNotificationWithIcon(
                  "warning",
                  t("invalid_login"),
                  t("please_try_again")
                );
                logout();
                this.props.history.push("/login");
              } else {
                this.openNotificationWithIcon(
                  "warning",
                  t("something_went_wrong"),
                  t("please_contact_adminstrator")
                );
              }
            });
        }else {
          this.setState({ gotData: true });
        }
      }
    
      render() {return (
          <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-xl-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
                </Breadcrumb>
                <h4>{this.state.title}</h4>
                <p className="mb-0">
                {this.state.description}
                </p>
              </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                {this.state.gotData ? (
                  <Form
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={this.state.values}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                size="default"
                ref={this.formRef}
              >
              <Form.Item
    label= {t("id")}
    key="100"
    name="clientsays_id"
  >
    <Input
      placeholder= {t("auto_incerment")}
      disabled="true"
    />
  </Form.Item>
  <Form.Item
                  label= {t("client__name")}
                  key="0"
                  name="client_name"
                  rules={
                    [
                      {required : 1,          
                          message: t("please_enter_a_client_name")}

                  ]
                }
                >
                  <Input
                    placeholder= {t("enter_a_client_name")}
                    disabled=""
                  />
                </Form.Item><Form.Item
                  label= {t("client_designation")}
                  key="1"
                  name="client_designation"
                  rules={
                    [
                      {required : 1,          
                          message: t("please_enter_a_client_designation")}

                  ]
                }
                >
                  <Input
                    placeholder= {t("enter_a_client_designation")}
                    disabled=""
                  />
                </Form.Item><Form.Item
          label= {t("client_says")}
          key="2"
        ><ReactQuill theme="snow" value={this.state.htmleditervalueclient_says} onChange={this.htmlediterOnChangeclient_says}/></Form.Item><Form.Item
        label= {t("client_picture")}
        key="3"
        name="client_picture"
        rules={
          [
            {required : 1,
            message: t("client_picture_is_required")}
        ]
      }
      >
      <Upload 
      name={this.state.uploadprops.name}
      action={this.state.uploadprops.action}
      header={this.state.uploadprops.headers}
      onChange={this.uploadOnChangeclient_picture}
      >
          <Button icon={<UploadOutlined />}>{t("click_to_upload")}</Button>
        </Upload>
      </Form.Item>
                <Form.Item
                      
                      label={t("active")}
                      key="101"
                    >
                      <Switch
                        name="status"
                        key="101"
                        onChange={this.switchOnChange}
                        defaultChecked={this.state.values.status}
                      />
                    </Form.Item>
    <Form.Item justify="center">
    <Button
      className="paddme"
      onClick={this.cancelFun}
    >
      {t("cancel")}
    </Button>

    <Button type="primary" htmlType="submit">
      {this.state.submitButtonName}
    </Button>
  </Form.Item>

              </Form>
                ) : (<div className="loadingpsin">
                  <Spin />
                </div>)}
              </Card.Body>
            </Card>
          </>
        );
      }
    }
    
    
    export default ClientSaysAddEdit;
    
    