import React, { Component } from "react";
    import "./App.less";
    import "antd/dist/antd.less"; 
    import "./scss/volt.scss";
    
    import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
    
    import Home from "./components/Home";
    import SignIn from "./pages/auth/Login";
    import Dashboard from "./pages/Dashboard/DashboardOverview";
    import ResetPassword from "./pages/auth/ResetPassword";
    import ForgotPassword from "./pages/auth/ForgotPassword";
    import GenericNotFound from "./pages/NotFound/NotFound";
    import Lock from "./pages/auth/Lock";
    import PrivateRoute from "./components/PrivateRoute";
    import PublicRoute from "./components/PublicRoute";
    import UserType from "./pages/UserType/UserType";import UserTypeAddEdit from "./pages/UserType/UserTypeAddEdit";import User from "./pages/User/User";import UserAddEdit from "./pages/User/UserAddEdit";import UserDetails from "./pages/UserDetails/UserDetails";import UserDetailsAddEdit from "./pages/UserDetails/UserDetailsAddEdit";import Token from "./pages/Token/Token";import TokenAddEdit from "./pages/Token/TokenAddEdit";import Quotes from "./pages/Quotes/Quotes";import QuotesAddEdit from "./pages/Quotes/QuotesAddEdit";import Awards from "./pages/Awards/Awards";import AwardsAddEdit from "./pages/Awards/AwardsAddEdit";import ClientSays from "./pages/ClientSays/ClientSays";import ClientSaysAddEdit from "./pages/ClientSays/ClientSaysAddEdit";import Client from "./pages/Client/Client";import ClientAddEdit from "./pages/Client/ClientAddEdit";import Values from "./pages/Values/Values";import ValuesAddEdit from "./pages/Values/ValuesAddEdit";import Team from "./pages/Team/Team";import TeamAddEdit from "./pages/Team/TeamAddEdit";import Strength from "./pages/Strength/Strength";import StrengthAddEdit from "./pages/Strength/StrengthAddEdit";import Work from "./pages/Work/Work";import WorkAddEdit from "./pages/Work/WorkAddEdit";import RolePermissions from "./pages/RolePermissions/RolePermissions";import RolePermissionsAddEdit from "./pages/RolePermissions/RolePermissionsAddEdit";
    import RecentWork from "./pages/recentWork/Recentwork"
    import ContactUs from "./pages/ContactUs/ContactUs"
    import EditAddContactUs from "./pages/ContactUs/ContactUsAddEdit"
    import EditAddRecentWork from "./pages/recentWork/RecentWorkAddEdit"
    import { Routes } from "./routes";
    
    class App extends Component {
      render() {
        return (
          <BrowserRouter>
            <Switch>
              <PublicRoute restricted={false} component={Home} path="/" exact />

              <PublicRoute
                restricted={true}
                component={SignIn}
                path={Routes.Signin.path}
                exact
              />
              <PublicRoute
                restricted={true}
                component={ForgotPassword}
                path={Routes.ForgotPassword.path}
                exact
              />
              <PublicRoute
                restricted={true}
                component={ResetPassword}
                path={Routes.ResetPassword.path}
                exact
              />
              <PublicRoute
                restricted={true}
                component={Lock}
                path={Routes.Lock.path}
                exact
              />
              <PrivateRoute
            component={Dashboard}
            path={Routes.DashboardOverview.path}
            exact
          />
    <PrivateRoute
    component={UserType }
    path={Routes.UserType.pathview}
    exact
  /><PrivateRoute
    component={UserTypeAddEdit }
    path={Routes.UserType.pathedit}
    exact
  /><PrivateRoute
    component={User }
    path={Routes.User.pathview}
    exact
  /><PrivateRoute
    component={UserAddEdit }
    path={Routes.User.pathedit}
    exact
  /><PrivateRoute
    component={UserDetails }
    path={Routes.UserDetails.pathview}
    exact
  /><PrivateRoute
    component={UserDetailsAddEdit }
    path={Routes.UserDetails.pathedit}
    exact
  /><PrivateRoute
    component={Token }
    path={Routes.Token.pathview}
    exact
  /><PrivateRoute
    component={TokenAddEdit }
    path={Routes.Token.pathedit}
    exact
  /><PrivateRoute
    component={Quotes }
    path={Routes.Quotes.pathview}
    exact
  /><PrivateRoute
    component={QuotesAddEdit }
    path={Routes.Quotes.pathedit}
    exact
  /><PrivateRoute
    component={Awards }
    path={Routes.Awards.pathview}
    exact
  /><PrivateRoute
    component={AwardsAddEdit }
    path={Routes.Awards.pathedit}
    exact
  /><PrivateRoute
    component={ClientSays }
    path={Routes.ClientSays.pathview}
    exact
  /><PrivateRoute
    component={ClientSaysAddEdit }
    path={Routes.ClientSays.pathedit}
    exact
  /><PrivateRoute
    component={Client }
    path={Routes.Client.pathview}
    exact
  /><PrivateRoute
    component={ClientAddEdit }
    path={Routes.Client.pathedit}
    exact
  /><PrivateRoute
    component={Values }
    path={Routes.Values.pathview}
    exact
  /><PrivateRoute
    component={ValuesAddEdit }
    path={Routes.Values.pathedit}
    exact
  /><PrivateRoute
    component={Team }
    path={Routes.Team.pathview}
    exact
  /><PrivateRoute
    component={TeamAddEdit }
    path={Routes.Team.pathedit}
    exact
  /><PrivateRoute
    component={Strength }
    path={Routes.Strength.pathview}
    exact
  /><PrivateRoute
    component={StrengthAddEdit }
    path={Routes.Strength.pathedit}
    exact
  />
  <PrivateRoute
    component={Work }
    path={Routes.Work.pathview}
    exact
  /><PrivateRoute
    component={WorkAddEdit }
    path={Routes.Work.pathedit}
    exact
  />
  <PrivateRoute
    component={RecentWork }
    path={Routes.RecentWork.pathview}
    exact
  />
  <PrivateRoute
    component={EditAddRecentWork }
    path={Routes.RecentWork.pathedit}
    exact
  />
  <PrivateRoute
    component={EditAddContactUs }
    path={Routes.ContactUs.pathedit}
    exact
  />
   <PrivateRoute
    component={ContactUs }
    path={Routes.ContactUs.pathview}
    exact
  />
  <PrivateRoute
    component={RolePermissions }
    path={Routes.RolePermissions.pathview}
    exact
  /><PrivateRoute
    component={RolePermissionsAddEdit }
    path={Routes.RolePermissions.pathedit}
    exact
  />
              <Route path="/404" component={GenericNotFound} />
              <Redirect to="/404" />
            </Switch>
          </BrowserRouter>
        );
      }
    }
    
    export default App;
    