import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";

import { GetData as t } from "../../lingual";

import { Card } from "@themesberg/react-bootstrap";
import "./RolePermissions.less";
import {
  notification,
  Form,
  Input,
  Button,
  Switch,
  Spin,
  Row,
  Col,
  Empty,
} from "antd";

import axios from "../../axios";

import { logout } from "../../utils";

class RolePermissionsAddEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: t("role_permissions"),
      description: t("add_new_user_role_and_permission"),
      values: { status: true },
      gotData: false,
      active: true,
      submitButtonName: t("submit"),
      columns: [
        {
          title: "Module Name",
          dataIndex: "module_name",
          key: "module_name",
        },
        {
          title: "GET",
          dataIndex: "get",
          key: "get",
        },
        {
          title: "POST",
          dataIndex: "post",
          key: "post",
        },
        {
          title: "PUT",
          dataIndex: "put",
          key: "put",
        },
        {
          title: "DELETE",
          dataIndex: "delete",
          key: "delete",
        },
      ],
      dataSource: null,
    };

    this.onValuesChange = this.onValuesChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.onFinishFailed = this.onFinishFailed.bind(this);
    this.switchOnChange = this.switchOnChange.bind(this);
    this.switchPermissionChange = this.switchPermissionChange.bind(this);
  }
  switchPermissionChange = (key, index, e) => {
    let dataSource = this.state.dataSource;

    let currentData = dataSource[index];
    currentData[key] = e ? 1 : 0;

    dataSource[index] = currentData;
    this.setState({ dataSource: dataSource });
  };
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  formRef = React.createRef();
  onValuesChange = (d) => {};

  onFinish = (values) => {
    values.status = this.state.active ? 1 : 0;
    values.permissions = this.state.dataSource;
    let path = "roles";
    if (this.props.match.params.id === "new") {
      axios
        .post(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("role_permissions"),
                res.data.error.message
              );
            } else {
              path = window.location.pathname;
              let arr = path.split("/");

              this.props.history.push("/" + arr[1]);
              this.openNotificationWithIcon(
                "success",
                t("role_permissions"),
                t("data_added_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      path += "/" + this.props.match.params.id;
      axios
        .put(path, values)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                "Role Permissions",
                res.data.error.message
              );
            } else {
              let path = window.location.pathname;
              let arr = path.split("/");

              this.props.history.push("/" + arr[1]);
              this.openNotificationWithIcon(
                "success",
                "Role Permissions",
                t("data_updated_successfully")
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    }
  };
  onFinishFailed = (values) => {};

  switchOnChange = (checked) => {
    this.setState({ active: checked });
  };

  componentDidMount(prevProps) {
    if (this.props.match.params.id !== "new") {
      this.setState({
        description: t("edit_user_role_and_permission"),
        submitButtonName: t("update"),
      });

      let path = "roles/" + this.props.match.params.id;
      axios
        .get(path)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            if (res.data.error) {
              this.openNotificationWithIcon(
                "warning",
                t("role_permission"),
                res.data.error.message
              );
            } else {
              this.setState({
                values: res.data.data,
                gotData: true,
                active: res.data.data.status,
              });
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.openNotificationWithIcon(
              "warning",
              t("invalid_login"),
              t("please_try_again")
            );
            logout();
            this.props.history.push("/login");
          } else {
            this.openNotificationWithIcon(
              "warning",
              t("something_went_wrong"),
              t("please_contact_adminstrator")
            );
          }
        });
    } else {
      this.setState({ gotData: true });
    }

    let path1 = "permission/role/" + this.props.match.params.id;
    axios
      .get(path1)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.error) {
            this.openNotificationWithIcon(
              "warning",
              t("role_permission"),
              res.data.error.message
            );
          } else {
            this.setState({
              dataSource: res.data.data,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.openNotificationWithIcon(
            "warning",
            t("invalid_login"),
            t("please_try_again")
          );
          logout();
          this.props.history.push("/login");
        } else {
          this.openNotificationWithIcon(
            "warning",
            t("something_went_wrong"),
            t("please_contact_adminstrator")
          );
        }
      });
  }

  render() {
    let keys = [];
    this.state.columns.map((data, index) => {
      keys.push(data.dataIndex);
    });
    console.log(keys);
    let dataSource = this.state.dataSource;
    return (
      <>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{this.state.title}</Breadcrumb.Item>
            </Breadcrumb>
            <h4>{this.state.title}</h4>
            <p className="mb-0">{this.state.description}</p>
          </div>
        </div>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            {this.state.gotData ? (
              <Form
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 14,
                }}
                layout="horizontal"
                initialValues={this.state.values}
                onValuesChange={this.onValuesChange}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                size="default"
                ref={this.formRef}
              >
                <Form.Item label={t("id")} key="100" name="roles_id">
                  <Input placeholder={t("auto_incerment")} disabled="true" />
                </Form.Item>
                <Form.Item
                  label={t("role_name")}
                  key="0"
                  name="role_name"
                  rules={[
                    { required: 1, message: t("please_enter_a_role_name") },
                  ]}
                >
                  <Input placeholder={t("enter_a_role_name")} disabled="" />
                </Form.Item>
                <Form.Item label={t("active")} key="101">
                  <Switch
                    name="status"
                    key="101"
                    onChange={this.switchOnChange}
                    defaultChecked={this.state.values.status}
                  />
                </Form.Item>
                <Form.Item label={t("user_permission")} key="102">
                  {this.state.dataSource ? (
                    <>
                      <Row className="customTableRow">
                        {this.state.columns.map((data, index) => (
                          <Col
                            className="customHeaderRowCell"
                            span={index === 0 ? 6 : 4}
                          >
                            {data.title}
                          </Col>
                        ))}
                      </Row>
                      {this.state.dataSource.map((data, index) => (
                        <Row>
                          {keys.map((key, ind) => (
                            <Col
                              className="customBodyRowCell"
                              span={ind === 0 ? 6 : 4}
                            >
                              {ind === 0 ? (
                                data[key]
                              ) : (
                                <Switch
                                  onChange={(e) =>
                                    this.switchPermissionChange(key, index, e)
                                  }
                                  defaultChecked={
                                    data[key] === "1" ? true : false
                                  }
                                />
                              )}
                            </Col>
                          ))}
                        </Row>
                      ))}
                    </>
                  ) : (
                    <Empty />
                  )}
                </Form.Item>
                <Form.Item justify="center">
                  <Button
                    className="paddme"
                    onClick={this.props.history.goBack}
                  >
                    {t("cancel")}
                  </Button>

                  <Button type="primary" htmlType="submit">
                    {this.state.submitButtonName}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <div className="loadingpsin">
                <Spin />
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default RolePermissionsAddEdit;
