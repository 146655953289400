
export const Routes = {
    MainWebsite: { path: "https://www.perimeter.co.in" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:token" },
    Signin: { path: "/login" },
    Lock: { path: "/lock" },
    DashboardOverview: { path: "/dashboard" },
    UserType: { pathview: "/user-type", pathedit: "/user-type/:id", addnew: "/user-type/new" },
    User: { pathview: "/user", pathedit: "/user/:id", addnew: "/user/new" },
    UserDetails: { pathview: "/user-details", pathedit: "/user-details/:id", addnew: "/user-details/new" },
    Token: { pathview: "/token", pathedit: "/token/:id", addnew: "/token/new" }, 
    Quotes: { pathview: "/quotes", pathedit: "/quotes/:id", addnew: "/quotes/new" }, 
    Awards: { pathview: "/awards", pathedit: "/awards/:id", addnew: "/awards/new" }, 
    ClientSays: { pathview: "/client-says", pathedit: "/client-says/:id", addnew: "/client-says/new" },
    Client: { pathview: "/client", pathedit: "/client/:id", addnew: "/client/new" }, 
    Values: { pathview: "/values", pathedit: "/values/:id", addnew: "/values/new" }, 
    Team: { pathview: "/team", pathedit: "/team/:id", addnew: "/team/new" }, 
    Strength: { pathview: "/strength", pathedit: "/strength/:id", addnew: "/strength/new" }, 
    Work: { pathview: "/work", pathedit: "/work/:id", addnew: "/work/new" }, 
    RecentWork : { pathview: "/recentWork", pathedit: "/recentWork/:id", addnew: "/recentWork/new" },
    ContactUs : { pathview: "/conntactUs", pathedit: "/conntactUs/:id", addnew: "/conntactUs/new" },
    RolePermissions: { pathview: "/role-permissions", pathedit: "/role-permissions/:id", addnew: "/role-permissions/new" },
};