import React, { useState, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
} from "@themesberg/react-bootstrap";
import axios from "../axios";
import { withRouter } from "react-router-dom";
import Profile from "../assets/img/team/profile.webp";
import { GetData as t } from "../lingual";

import storage from "../services/storage/LocalStorageService";
import { notification } from "antd";

import { logout } from "../utils";

class Navigationbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_name: localStorage.getItem("user_name")
        ? localStorage.getItem("user_name")
        : "",
      profileurl: localStorage.getItem("user_picture")
        ? localStorage.getItem("user_picture") === "null"
          ? ""
          : localStorage.getItem("user_picture")
        : "",
    };

    this.logoutUser = this.logoutUser.bind(this);
  }
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  /*componentDidMount(prevProps) {
    axios
      .get("profile")
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.error) {
            this.openNotificationWithIcon(
              "warning",
              t("login_screen"),
              res.data.error.message
            );
          } else {
            this.setState({
              user_name: res.data.data.user_name,
              profileurl: res.data.data.user_picture
                ? res.data.data.user_picture
                : "",
            });
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.openNotificationWithIcon(
            "warning",
            t("invalid_login"),
            t("please_try_again")
          );

          logout();
          this.props.history.push("/login");
        } else {
          this.openNotificationWithIcon(
            "warning",
            t("something_went_wrong"),
            t("please_contact_adminstrator")
          );
        }
      });
  }*/

  // const[notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  markNotificationsAsRead = () => {
    setTimeout(() => {
      // setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  logoutUser = (event) => {
    storage.clearToken();
    logout();
    console.log("Logout User", this.props);
    // location.reload();
    this.props.history.push("/login");
  };

  Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };
  render() {
    return (
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar">
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
            <Nav className="align-items-center">
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className="text-dark icon-notifications me-lg-3"
                >
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faBell} className="bell-shake" />
                    {/* {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />} */}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                  <ListGroup className="list-group-flush">
                    <Nav.Link
                      href="#"
                      className="text-center text-primary fw-bold border-bottom border-light py-3"
                    >
                      {t("notifications")}
                    </Nav.Link>

                    {/* {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)} */}

                    <Dropdown.Item className="text-center text-primary fw-bold py-3">
                      {t("view_all")}
                    </Dropdown.Item>
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image
                      src={
                        this.state.profileurl === ""
                          ? Profile
                          : this.state.profileurl
                      }
                      className="user-avatar md-avatar rounded-circle"
                    />

                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">
                        {this.state.user_name}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  {/* <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                    Profile
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => this.props.history.push("/settings")}
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" />{" "}
                    {t("settings")}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{" "}
                    {t("messages")}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                    {t("support")}
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold" onClick={this.logoutUser}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    {t("logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(Navigationbar);
