import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils';


import Preloader from "./Preloader";


const PublicRoute = ({component: Component, restricted, ...rest}) => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);


    return (
        <Route {...rest} render={props => (
            isLogin() && restricted ?
                <Redirect to="/dashboard" />
            :  <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>
        )} />
    );
};

export default PublicRoute;