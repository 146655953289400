import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faEye,
  faEyeSlash,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
// import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { GetData as t } from "../../lingual";
import { notification } from "antd";
import { Link } from "react-router-dom";
// import { GetData as t } from "../../lingual";

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { logout } from "../../utils";
import axios from "../../axios";

import "./login.less";

import storage from "../../services/storage/LocalStorageService";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: localStorage.getItem("username")
        ? localStorage.getItem("username")
        : "",
      password: localStorage.getItem("password")
        ? localStorage.getItem("password")
        : "",
      showPassword: false,
      langauges: [],
      checkboxChecked: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.changeLangauge = this.changeLangauge.bind(this);
  }

  changeLangauge(langkey) {
    // console.log("Chnage", language);
    storage.setLanguage(langkey);
    let langauges = this.state.langauges;
    langauges.map((item) => {
      item.active = item.langkey === langkey ? true : false;
    });

    this.setState({ langauges: langauges });
  }

  componentDidMount(prevProps) {
    let data = {
      username: this.state.email,
    };
    axios
      .post("languages/active", data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.error) {
            this.openNotificationWithIcon(
              "warning",
              t("login_screen"),
              res.data.error.message
            );
          } else {
            // storage.setToken(res.data);
            // this.props.history.push("/dashboard");
            // console.log("dashboard");
            let languages = res.data.data;
            let langkey = storage.getLanguage();
            languages.map((item) => {
              item.active = item.langkey === langkey ? true : false;
            });

            this.setState({ langauges: languages });
            console.log(languages);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.openNotificationWithIcon(
            "warning",
            t("invalid_login"),
            t("please_try_again")
          );

          logout();
          this.props.history.push("/login");
        } else {
          this.openNotificationWithIcon(
            "warning",
            t("something_went_wrong"),
            t("please_contact_adminstrator")
          );
        }
      });
  }
  handleClickShowPassword(e) {
    console.log("handleClickShowPassword", this.state.showPassword);
    this.setState({ showPassword: !this.state.showPassword });
    console.log("handleClickShowPassword", this.state.showPassword);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  handleSubmit = (event) => {
    let data = {
      username: this.state.email,
      password: this.state.password,
    };

    if (this.state.checkboxChecked) {
      localStorage.setItem("username", this.state.email);
      localStorage.setItem("password", this.state.password);
    }

    axios
      .post(`auth/login`, data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.error) {
            this.openNotificationWithIcon(
              "warning",
              t("login_screen"),
              res.data.error.message
            );
          } else {
            storage.setToken(res.data);
            this.props.history.push("/dashboard");
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.openNotificationWithIcon(
            "warning",
            t("invalid_login"),
            t("please_try_again")
          );

          logout();
          this.props.history.push("/login");
        } else {
          console.log(error);
          this.openNotificationWithIcon(
            "warning",
            t("something_went_wrong"),
            t("please_contact_adminstrator")
          );
        }
      });

    event.preventDefault();
  };

  render() {
    return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            {/* <p className="text-center">
              <Card.Link
                href={Routes.MainWebsite.path}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                homepage
              </Card.Link>
            </p> */}
            <Row
              className="justify-content-center form-bg-image"
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{t("sign_title")}</h3>
                  </div>
                  <Form className="mt-4" onSubmit={this.handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>{t("your_email")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          type="email"
                          placeholder={t("email_placeholder")}
                          onChange={this.handleEmailChange}
                          value={this.state.email}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>{t("your_password")}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type={this.state.showPassword ? "text" : "password"}
                            placeholder={t("password_placeholder")}
                            onChange={this.handlePasswordChange}
                            value={this.state.password}
                          />
                          <InputGroup.Text className="handCursor">
                            <FontAwesomeIcon
                              onClick={this.handleClickShowPassword}
                              icon={
                                this.state.showPassword ? faEyeSlash : faEye
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Form.Check type="checkbox">
                          <FormCheck.Input
                            id="defaultCheck5"
                            className="me-2"
                            checked={this.state.checkboxChecked}
                            onChange={(e) => {
                              console.log("Changed");
                              this.setState({
                                checkboxChecked: !this.state.checkboxChecked,
                              });
                            }}
                          />
                          <FormCheck.Label
                            htmlFor="defaultCheck5"
                            className="mb-0"
                          >
                            {t("remember_me")}
                          </FormCheck.Label>
                        </Form.Check>
                        <Card.Link
                          as={Link}
                          to={Routes.ForgotPassword.path}
                          className="small text-end"
                        >
                          {t("lost_password")}
                        </Card.Link>
                      </div>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                      {t("sign_in")}
                    </Button>
                  </Form>
                  {this.state.langauges.length > 0 ? (
                    <div className="d-flex my-4">
                      <p className="langstyle">{t("change_language")} -</p>
                      {this.state.langauges.map((item, index) => (
                        <p
                          className={
                            item.active
                              ? "handCursor langstyle langactive"
                              : "handCursor langstyle"
                          }
                          key={index}
                          onClick={() => this.changeLangauge(item.langkey)}
                        >
                          {item.value}
                        </p>
                      ))}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
}

export default Login;
