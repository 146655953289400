import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Breadcrumb } from '@themesberg/react-bootstrap';

// import { Card } from '@themesberg/react-bootstrap';

// import { Table, Tag, Space, Popconfirm, notification, Spin } from 'antd';

// import axios from "../../axios";

// import storage from "../../services/storage/LocalStorageService"

// import { logout } from '../../utils';

import { Nav, Badge, Image } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

class NavItem extends Component {
  constructor(props) {
    super(props);

    const { link, external, badgeText, get, post, put, del } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    const linkProps = external
      ? { href: link }
      : {
          as: Link,
          to: {
            pathname: link,
            state: { get: get, post: post, put: put, delete: del },
          },
        };
    // console.log(linkProps, "linkProps");
    this.state = {
      classNames: classNames,
      linkProps: linkProps,
      badgeBg: "secondary",
      badgeColor: "primary",
      link: link,
    };
  }

  render() {
    let path = window.location.pathname;
    const navItemClassName = this.state.link === path ? "active" : "";
    // console.log(this.props.showItem);
    return (
      <>
        {/* {this.props.showItem === 1 ? ( */}
        <Nav.Item className={navItemClassName} onClick={this.props.buttonClick}>
          <Nav.Link
            {...this.state.linkProps}
            target={this.props.target}
            className={this.state.classNames}
          >
            <span>
              {this.props.icon ? (
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={this.props.icon} />{" "}
                </span>
              ) : null}
              {this.props.image ? (
                <Image
                  src={this.props.image}
                  width={20}
                  height={20}
                  className="sidebar-icon svg-icon"
                />
              ) : null}

              <span className="sidebar-text">{this.props.title}</span>
            </span>
            {this.props.badgeText ? (
              <Badge
                pill
                bg={this.props.badgeBg}
                text={this.props.badgeColor}
                className="badge-md notification-count ms-2"
              >
                {this.props.badgeText}
              </Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
      </>
    );
  }
}

export default NavItem;
